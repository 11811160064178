import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faChevronRight, faChevronLeft, faDownload, faHistory  } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import config from '../config';
import '../styles/PrescriptionHistory.css'; 
import { Table, Modal } from 'react-bootstrap';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import bg from "../logo/bgtemplate.jpg";
import pdfbg from "../logo/template.png";

function PrescriptionHistory() {
  const [uhid, setUhid] = useState('');
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [print, setPrint] = useState([]);
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [uhidOptions, setUHIDOptions] = useState([]); 
  const [selectedUHIDOptions, setSelectedUHIDOptions] = useState([]);
  const [selectedUHID, setSelectedUHID] = useState('');
  const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);
  const [showUHIDSelectionModal, setShowUHIDSelectionModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [showPrintComponent, setShowPrintComponent] = useState(false); 

  useEffect(() => {
    toast.info('Search by UHID', {
      autoClose: 3000, 
    });
  }, []);

  const viewPrescription = (uhid) => {
    axios.get(`${config.apiUrl}/getprescription/${uhid}`)
      .then(response => {
        const data = response.data.prescriptionData;
        data.sort((a, b) => new Date(a.consultationDate) - new Date(b.consultationDate));
        const filteredData = data.filter(prescription => prescription.uhid === uhid);
        setSelectedPrescription(uhid);
        setPrescriptionData(filteredData);
        

      })
      .catch(error => {
        console.error('Error fetching prescription data for selected UHID:', error);
      });
  };

  const closeModal = () => {
    setSelectedPrescription(null);
  };

  useEffect(() => {
    fetchUHIDs(); 
  }, []);

  const fetchUHIDs = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/patientregistration/listuhids`);
      console.log("response", response);
      setUHIDOptions(response.data);
    } catch (error) {
      console.error('Error fetching UHIDs:', error);
    }
  };

  const handleUHIDSelect = async () => {
    const searchTerm = uhid.trim();
    console.log(searchTerm);
    if (isNaN(searchTerm)) {
      axios.get(`${config.apiUrl}/getprescription/${searchTerm}`)
        .then(response => {
          const data = response.data.prescriptionData;
          setPrint (data);
const uniqueData = [...new Map(data.map(item => [item['uhid'], item])).values()];
          setPrescriptionData(uniqueData);
        })
        .catch(error => {
          console.error('Error fetching prescription data:', error);
        });
    } else {
      axios.get(`${config.apiUrl}/getprescription/mobile/${searchTerm}`)
        .then(response => {
          const data = response.data.prescriptionData;
          if (data.length === 0) {
            setShowNotFoundMessage(true);
            setPrescriptionData([]);
          } else if (data.length === 1) {
            setPrescriptionData(data);
          } else {
            const uniqueUHIDs = [...new Set(data.map(prescription => prescription.uhid))];
            if (uniqueUHIDs.length === 1) {
              const lastUpdatedData = data.filter((prescription, index) => index === data.length - 1);
              setPrescriptionData(lastUpdatedData);
            } else {
              const uhidOptions = data.map(prescription => `${prescription.uhid}-${prescription.patientname}`);
              handleUHIDSelection(uhidOptions);
            }
          }
        })
        .catch(error => {
          console.error('Error fetching prescription data:', error);
        });
    }
  };

  const handleUHIDSelection = (uhidOptions) => {
    const uniqueUHIDs = [...new Set(uhidOptions)];
      setSelectedUHIDOptions(uniqueUHIDs);
    setShowUHIDSelectionModal(true);
  };

  const handleClosePrint = () => {
    setShowPrintComponent (false)
  }
  

  const handleSelectedUHID = (selectedUHID) => {
    const [uhid] = selectedUHID.split("-");
    axios.get(`${config.apiUrl}/getprescription/${uhid}`)
      .then(response => {
        const data = response.data.prescriptionData;
        setPrescriptionData(data);
        setShowUHIDSelectionModal(false);
      })
      .catch(error => {
        console.error('Error fetching prescription data for selected UHID:', error);
      });
  };
  

  const handleKeyDown = (e) => {
    if (e.keyCode === 8) {
      setPrescriptionData('');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUhid(value); 
    setShowNotFoundMessage(false); 
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const itemsPerPage = 1;

const totalPages = Math.ceil(prescriptionData.length / itemsPerPage);

const goToPage = (pageNumber) => {
  setCurrentPage(pageNumber);
};

const getPageNumbers = () => {
  const pageNumbers = [];
  for (let i = 0; i < totalPages; i++) {
    pageNumbers.push(i + 1);
  }
  return pageNumbers;
};

const handlePdf = async () => {
  const html2canvasOptions = {
    scale: 2,
    logging: false,
    allowTaint: true,
    backgroundColor: null
  };

  const jsPDFOptions = {
    orientation: "portrait",
    unit: "mm",
    format: "a4",
  };

  const doc = new jsPDF(jsPDFOptions);

  const backgroundWidth = doc.internal.pageSize.width;
  const backgroundHeight = doc.internal.pageSize.height;
  doc.addImage(pdfbg, 'PNG', 0, 0, backgroundWidth, backgroundHeight);

  let currentY = 30;
  let totalPages = 1;
  let contentHeight = 0;

  const bottomSpace = 10;

  for (let index = 0; index < prescriptionData.length; index++) {
    const capture = document.querySelector(`#bill-${index}`);

    const canvas = await html2canvas(capture, html2canvasOptions);
    const imgData = canvas.toDataURL("image/png");

    const imageWidth = 180;
    const imageHeight = (canvas.height * imageWidth) / canvas.width;

    if (currentY + imageHeight + bottomSpace > doc.internal.pageSize.height) {
      doc.addPage();
      totalPages++;
      doc.addImage(pdfbg, 'PNG', 0, 0, backgroundWidth, backgroundHeight);
      currentY = 30;
    }

    const marginLeft = (doc.internal.pageSize.width - imageWidth) / 2;

    if (index !== 0) {
      currentY += 1;
    }
    doc.addImage(
      imgData,
      "PNG",
      marginLeft,
      currentY,
      imageWidth,
      imageHeight
    );
    currentY += imageHeight + bottomSpace;
    contentHeight += imageHeight + bottomSpace;

    if (contentHeight > doc.internal.pageSize.height) {
      doc.addPage();
      totalPages++;
      doc.addImage(pdfbg, 'PNG', 0, 0, backgroundWidth, backgroundHeight);
      currentY = 30;
      contentHeight = 0;
    }

    capture.style.backgroundColor = "";
  }
  const filename = `History_${prescriptionData[0].uhid}.pdf`;
  doc.save(filename);
};

const openWhatsAppChat = () => {
  const patientMobileNumber = prescriptionData[0].mobile_number;
  if (patientMobileNumber) {
    const whatsappLink = `https://wa.me/${patientMobileNumber}`;
    window.open(whatsappLink, "_blank");
  } else {
    console.error('Patient mobile number not available.');
  }
};


  return (
    <div className="container">
      <style>
        {
          `

          .modal-content {
            background-image: url(${bg});
            background-size: cover;
            background-color : transparent;
          }

          .blur {
            filter: blur(5px);
          }
          
          /* Style the scrollbar for webkit-based browsers */
.modal-body::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

/* Style the track of the scrollbar */
.modal-body::-webkit-scrollbar-track {
  background-color: transparent; /* Set the background color of the track */
}

/* Style the thumb of the scrollbar */
.modal-body::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar thumb */
  border-radius: 5px; /* Set the border radius of the thumb */
}

/* Style the corner of the scrollbar */
.modal-body::-webkit-scrollbar-corner {
  background-color: transparent; /* Set the background color of the scrollbar corner */
}@media (max-width: 767px) {
  /* Styles for screens smaller than 767px (typical mobile screens) */

  .container {
    padding: 10px; /* Adjust the padding for smaller screens */
  }

  .input-group {
    flex-direction: column; /* Stack the input elements vertically */
  }

  /* Adjust any other styles as needed for mobile screens */
  .modal-content {
    background-size: contain; /* Adjust background size for smaller screens */
  }

  /* ... (Add other mobile-specific styles as needed) */
}
@media (min-width: 768px) {
  /* Styles for screens larger than or equal to 768px */

  .input-group {
    flex-direction: row; /* Reset the input elements to horizontal for larger screens */
  }

  .header-table, .main-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .header-table td, .main-table th, .main-table td {
    border: 1px solid grey ;
    padding: 8px;
  }
  
  .header-table td {
    font-weight: bold;
  }
  
  .main-table th {
    text-align: left;
  }
  
  .text-center {
    text-align: center;
  }
  

}
`
        }
      </style>
      <ToastContainer
        className='animated-alert' 
        toastClassName='animated-toast' 
        bodyClassName='animated-toast-body' 
      />

      <div className={`row mt-3 content ${selectedPrescription ? "blur" : ""} `}>

        <div className="col">
          <h1 className="page-header">Patient History</h1>
        </div>
        <div className="col">
          <div className="input-group">
            <input
              type="text"
              placeholder="Search by UHID"
              name="uhidSearch"
              value = {uhid}
              size="sm"
              list="uhidOptions"
              style={{ width: "300px" }}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
            />
            <datalist id="uhidOptions">
              {uhidOptions.map((option, index) => (
                <option key={index} value={option} />
              ))}
            </datalist>
            <FontAwesomeIcon icon={faSearch} onClick={handleUHIDSelect} className="mt-2" style={{ cursor: "pointer" }} />
          </div>
        </div>
      </div>

      <div className={`row mt-3 content ${selectedPrescription ? "blur" : ""} `}>

        <div className="col m-3">
          <table className="table table-striped table-bordered" style={{ tableLayout: 'fixed' }}>
            <colgroup>
              <col style={{ width: '30%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '30%' }} />
            </colgroup>
            <thead className='custom-table-head'>
              <tr>
                <th  className='text-center'>Date</th>
                <th  className='text-center'>UHID</th>
                <th  className='text-center'>Patient Name</th>
                <th  className='text-center'>Report</th>
              </tr>
            </thead>
            <tbody>
              {prescriptionData && prescriptionData.map((prescription, index) => (
                <tr key={index}>
                  <td  className='text-center' >{(prescription.date).split(',')[0].trim()}</td>
                  <td  className='text-center'>{prescription.uhid}</td>
                  <td  className='text-center'>{prescription.patientname}</td>
                  <td  className='text-center'>
                    <img
                      src="https://cdn-icons-png.flaticon.com/128/1321/1321938.png"
                      alt="Report Icon"
                      style={{ width: '24px', height: '24px', cursor:'pointer'}}
                      onClick={() => viewPrescription(prescription.uhid)}
                    /> 
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {selectedPrescription && (
        <div className={`modal fade show ${showPrintComponent ? "blur" : ""}`} style={{ display: 'block'}} tabIndex="-1">
  <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '65%'}}>
  <div className="modal-content" style={{ maxHeight: '580px', backgroundColor: 'transparent'}} >
        <div className="modal-header">
          <h5 className="modal-title"></h5>   
          <button type="button" className="btn-close" onClick={closeModal} style={{ cursor: "pointer" }}></button>
        </div>
        <div className="modal-header d-flex justify-content-center">
          <h5 className="modal-title text-center">
            <p className="fw-bold mb-2 text-center">OCEAN DENTAL CARE</p>
            <p className="fw-bold mb-2 text-center">110-114, 1st Floor-PTR Sweets, P.P.Chavadi, Theni Main Road</p>
            <p className="fw-bold mb-2 text-center">Thirumalai Colony Main, Madurai - 625 016</p>
            <p className="fw-bold mb-2 text-center">Phno : +91 7305150160, 0452-3559075 E-mail: odc@gmail.com</p>
          </h5>   
        </div>
        <div className="modal-body " style={{ maxHeight: '580px', overflowY:"auto"}}>
          <div className="text-start">
            <div className="row">
              <div className="col">
                <p><b>Patient Name:</b> {prescriptionData[currentPage].patientname}</p>
                <p><b>UHID: </b>{prescriptionData[currentPage].uhid}</p>
              </div>
              <div className="col text-end">
                <p><b>Age:</b> {prescriptionData[currentPage].age}</p>
                <p><b>Gender:</b> {prescriptionData[currentPage].gender}</p>
              </div>
            </div>
          </div>

          {prescriptionData[currentPage] && (
            <div key={currentPage}>

              <p><b>APPOINT ON : {prescriptionData[currentPage].date.split(',')[0]}</b></p>
              <p>Problem detected: {prescriptionData[currentPage].medicalaffiction}</p>
              <p>Treatment Given: {prescriptionData[currentPage].treatmentgiven}</p>
              <br/>

              {prescriptionData[currentPage].prescription && JSON.parse(prescriptionData[currentPage].prescription).some(item => item.name.trim() !== '') && (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Brand Name</th>
                      <th>Frequency</th>
                      <th>Instruction</th>
                      <th colSpan="4"> Time Schedule</th>
                    </tr>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="text-center">Morning</th>
                      <th className="text-center">Afternoon</th>
                      <th className="text-center">Evening</th>
                      <th className="text-center">Night</th>
                    </tr>
                  </thead>
                  <tbody>
                    {JSON.parse(prescriptionData[currentPage].prescription).map((item, idx) => (
                      item.name.trim() !== '' && (
                        <tr key={idx}>
                          <td>{item.name}</td>
                          <td>{item.brandname}</td>
                          <td>{item.frequency}</td>
                          <td>{item.instruction}</td>
                          <td className="text-center">{item.morning ? '✔' : ''}</td>
                          <td className="text-center">{item.afternoon ? '✔' : ''}</td>
                          <td className="text-center">{item.evening ? '✔' : ''}</td>
                          <td className="text-center">{item.night ? '✔' : ''}</td>
                        </tr>
                      )
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}

        </div>
        <div className="modal-footer justify-content-between">
          <div className='d-flex justify-content-start align-items-center'> 
            <span>Total Pages: {totalPages}&nbsp;&nbsp;</span>
            <button className="btn btn-primary" onClick={() => setShowPrintComponent(true)}>
              <FontAwesomeIcon icon={faHistory} />
            </button>    
          </div>
          <div>
            <button type="button" className="btn btn-primary" onClick={previousPage} disabled={currentPage === 0}>
              <FontAwesomeIcon icon={faChevronLeft} /> 
            </button>
            {getPageNumbers().map((pageNumber) => (
              <button key={pageNumber} onClick={() => goToPage(pageNumber - 1)}>
                {pageNumber}
              </button>
            ))}
            <button type="button" className="btn btn-primary" onClick={nextPage} disabled={currentPage === prescriptionData.length - 1}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
)}


{showPrintComponent && (
  <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" >
    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '65%' }}>
      <div className="modal-content" style={{  maxHeight: '580px' }}>
        <div className="modal-header d-flex justify-content-end">
        <button className="btn" style={{ backgroundColor: "rgb(72, 194,205)", color: "white" }} onClick={handlePdf}>
  <FontAwesomeIcon icon={faDownload} />
</button>   

<div className="modal-footer justify-content-between">
  <div className="d-flex justify-content-start align-items-center"> 
    
    <button className="btn btn-success ms-2" onClick={openWhatsAppChat}>
      <FontAwesomeIcon icon={faWhatsapp} />
    </button>    
  </div>

</div>

<button type="button" className="btn-close" onClick={handleClosePrint}></button>
        </div>
        
        <div className="modal-header d-flex justify-content-center">
  <div className="fw-bold mb-2 text-center">
  <div>OCEAN DENTAL CARE</div>
    <div>110-114, 1st Floor-PTR Sweets, P.P.Chavadi, Theni Main Road</div>
    <div>Thirumalai Colony Main, Madurai - 625 016</div>
    <div>Phno : +91 7305150160, 0452-3559075</div>
    <div>E-mail: odc@gmail.com</div>
    <br/>
    <div className='text-end '>
            <p><b>UHID :</b> {prescriptionData[0].uhid}</p>
            <p><b>Patient Name : </b>{prescriptionData[0].patientname}</p>
          </div>
  </div>
</div>
<div className="modal-body " style={{ maxHeight: '580px', overflowY:"auto"}}>

                    {prescriptionData.map((data, index) => (
          
          
          <div key={index} className="bill" id={`bill-${index}`} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
  <div style={{ marginRight: '20px' }}>{index + 1}.</div>
  <div style={{ flex: '1' }}>
    <table className="header-table">
      <tbody>
        <br/>
        <tr>
          <td ><b> APPOINT ON</b></td>
          <td>{data.date.split(",")[0]}</td>
        </tr>
        <tr>
          <td ><b>Problem detected</b></td>
          <td >{data.medicalaffiction}</td>
        </tr>
        <tr>
          <td ><b>Treatment Given</b></td>
          <td >{data.treatmentgiven}</td>
        </tr>
      </tbody>
    </table>

    {data.prescription && JSON.parse(data.prescription).some(item => item.name.trim() !== '') && (
      <table className="main-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Brand Name</th>
            <th>Frequency</th>
            <th>Instruction</th>
            <th colSpan="4"> Time Schedule</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th className="text-center">Morning</th>
            <th className="text-center">Afternoon</th>
            <th className="text-center">Evening</th>
            <th className="text-center">Night</th>
          </tr>
        </thead>
        <tbody>
          {JSON.parse(data.prescription).map((item, idx) => (
            item.name.trim() !== '' && (
              <tr key={idx}>
                <td>{item.name}</td>
                <td>{item.brandname}</td>
                <td>{item.frequency}</td>
                <td>{item.instruction}</td>
                <td className="text-center">{item.morning ? '✔' : ''}</td>
                <td className="text-center">{item.afternoon ? '✔' : ''}</td>
                <td className="text-center">{item.evening ? '✔' : ''}</td>
                <td className="text-center">{item.night ? '✔' : ''}</td>
              </tr>
            )
          ))}
        </tbody>
      </table>
    )}
  </div>
</div>

        

        
        
          ))}
        </div>
        
      </div>
    </div>
  </div>
)}

      {showUHIDSelectionModal && (
        <Modal show={showUHIDSelectionModal} onHide={() => setShowUHIDSelectionModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Select UHID</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <select className="form-select" onChange={(e) => handleSelectedUHID(e.target.value)}>
              <option value="">Select UHID</option>
              {selectedUHIDOptions.map((uhid, index) => (
                <option key={index} value={uhid}>{uhid}</option>
              ))}
            </select>
          </Modal.Body>
        </Modal>
      )}

    </div>
    
  );
}

export default PrescriptionHistory;
