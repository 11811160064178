import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Login from "./components/login";
import AddMedicine from "./components/addmedicine";
import StockDetailsPage from "./components/stock";
import Billing from "./components/billing";
import Purchase from "./components/purchase";
import BillingHis from "./components/billinghistory";
import RegistrationForm from "./components/registration";
import StockDetailsPage1 from "./components/pharmacystock";
import NavigationBar from "./components/dashboard";
import Home from "./components/home";
import AppointmentsCalendar from "./components/appointmentscalendar";
import Appointment from "./components/appointments";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './index.css'
import PatientForm from "./components/doctor";
import backgroundImage from './logo/bgtemplate.jpg'; 
import './App.css'// Import your background image

const App = () => {
  return (
    <div className="app-container" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', minHeight: '100vh' }}>
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup>
              <CSSTransition key={location.key} classNames="page" timeout={1000}>
                <div>
                  {location.pathname !== "/" && <NavigationBar />}
                  <Switch location={location}>

                    <Route path="/home" component={Home} />
                    <Route path="/addmedicine" component={AddMedicine} />
                    <Route path="/register" component={RegistrationForm} />
                    <Route path="/stock" component={StockDetailsPage} />
                    <Route path="/pharmacystock" component={StockDetailsPage1} />
                    <Route path="/billing" component={Billing} />
                    <Route path="/purchase" component={Purchase} />
                    <Route path="/billinghistory" component={BillingHis} />
                    <Route path="/calendar" component={AppointmentsCalendar} />
                    <Route path="/appointment" component={Appointment} />
                    <Route path="/Doctor" component={PatientForm} />
                    <Route path="/" component={Login} />
                  </Switch>
                </div>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </div>
  );
};

export default App;
