import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import config from '../config';

const localizer = momentLocalizer(moment);
moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week
  },
  longDateFormat: {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'YYYY-MM-DD',
    LL: 'MMMM D, YYYY',
    LLL: 'MMMM D, YYYY h:mm A',
    LLLL: 'dddd, MMMM D, YYYY h:mm A',
  },
});
const AppointmentsCalendar = () => {
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    axios.get(`${config.apiUrl}/appointments`)
      .then(response => {
        setAppointments(response.data.map(appointment => ({
          appoint: appointment.mobileNo,
          title: appointment.patientName,
          start: moment(`${appointment.selectedDate} ${appointment.selectedTime}`, 'YYYY-MM-DD hh:mm A').toDate(),
          end: moment(`${appointment.selectedDate} ${appointment.selectedTime}`, 'YYYY-MM-DD hh:mm A').add(30, 'minutes').toDate(),
        })));
      })
      .catch(error => {
        console.error('Error fetching appointments:', error);
      });
  }, []);

  const Agenda = ({ event }) => (
    <div style={{ display: 'flex', flexDirection: 'column'  }}>
      <div style={{ display: 'flex', marginBottom: '10px' }}>
        <div className='dev' style={{ width: '120px', marginRight: '10px' }}><b>Patient Name :</b></div>
        <div>{event.title}</div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className='dev' style={{ width: '120px', marginRight: '10px' }}><b>Mobile No :</b></div>
        <div>{event.appoint}</div>
      </div>
    </div>
  );

  return (
    <div>
      <style>
        {
          `.rbc-event{
            background-color: rgb(72,194,205); 
          }
         .rbc-agenda-table tbody {
            background-color: rgb(72,194,205); 
            color:black;
          }
          .rbc-agenda-table th {
            text-align: center !important; 
          }

          @media (max-width: 600px) {
            .rbc-agenda-table tbody {
              background-color: transparent; /* Adjust the background color as needed */
            }

            /* Adjust other styles for better mobile responsiveness */
            .rbc-agenda-table td,
            .rbc-agenda-table th,
            .rbc-agenda-time-cell,
            .rbc-agenda-event-cell {
              padding: 2px; /* Adjust padding as needed */
              font-size: 8px; /* Adjust font size as needed */
            }

            .rbc-agenda-table th {
              text-align: center;
            }
            .dev{
              width:80px !important;
              
            }
          }
          `
        }
      </style>
      <Calendar
        localizer={localizer}
        events={appointments}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, borderRadius: "20px", boxShadow: "6px 6px 6px rgba(0, 0, 0, 0.5)" }}
        timeslots={1}
        min={moment().set({ hour: 17, minute: 0 }).toDate()}
        max={moment().set({ hour: 20, minute: 0 }).toDate()}
        step={30}
        views={['month', 'week', 'day', 'agenda']}
        className='m-4 bg-white'
        components={{
          agenda: {
            event: Agenda,
          },
        }}
      />
    </div>
  );
};

export default AppointmentsCalendar;
