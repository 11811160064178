import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import logo from "../../src/logo/logo - Copy.jpeg";
import { useReactToPrint } from "react-to-print";
import profile from "../../src/logo/pr.jpg";
import config from "../config";
import TablePopup from "./PopupTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    fatherName: "",
    dob: "",
    age: "",
    gender: "",
    mobileNumber: "",
    aadhaarNumber: "",
    homeAddress: ""
  });
  const [updateuhid ,setUpdateUhid] = useState('')
  const [highlightedFields, setHighlightedFields] = useState([]);
  const [submitting, setSubmitting] = useState(false); 
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [popupmsg, setPopupmsg] = useState('');

  const [initialFormData, setInitialFormData] = useState({});
  const [showIdCard, setShowIdCard] = useState(false);
  const componentRef = useRef();
  const [showAlert, setShowAlert] = useState(false); 
  const printRef = useRef();
  const [idCardData, setIdCardData] = useState(null);
  const [showPopup, setShowPopup] = useState(false); 
  const [selectedUHID, setSelectedUHID] = useState('');
  const [popupRows, setPopupRows] = useState([]);
  const [uhidOptions, setUHIDOptions] = useState([]); 

  useEffect(() => {
    fetchUHIDs(); 
  }, [])

  const fetchUHIDs = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/patientregistration/listuhids`);
      console.log("response", response);
      setUHIDOptions(response.data);

    } catch (error) {
      console.error('Error fetching UHIDs:', error);
    }
  };


  const handleKeyDown = (e) => {
    if (e.keyCode === 8) {
      setFormData({
        title: "",
        firstName: "",
        lastName: "",
        fatherName: "",
        dob: "",
        age: "",
        gender: "",
        mobileNumber: "",
        aadhaarNumber: "",
        homeAddress: "",
      });
    }
  };

  const formatDate = (dob) => {
    console.log("dob",dob);
    const date = new Date(dob);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    console.log("formattedMonth",formattedMonth);
    console.log("formattedDay",formattedDay);

    return `${year}-${formattedMonth}-${formattedDay}`;

  };

  const handlecancel = () => {
    setFormData({
      title: "",
      firstName: "",
      lastName: "",
      fatherName: "",
      dob: "",
      age: "",
      gender: "",
      mobileNumber: "",
      aadhaarNumber: "",
      homeAddress: "",
    });
  }

  const handleSearch = async () => {
    const searchTerm = selectedUHID.trim();
  
    if (isNaN(searchTerm)) {
      try {
        const response = await axios.get(`${config.apiUrl}/registration/${searchTerm}`);
        const patientData = response.data;
        console.log(patientData);
        

        if (patientData) {
          const formattedDob = formatDate(patientData.dob);
          setInitialFormData(patientData);
          console.log(patientData);

          setFormData({
            ...formData,
            title: patientData.title,
            firstName: patientData.firstName,
            lastName: patientData.lastName,
            fatherName: patientData.fatherName,
            dob: formattedDob,
            age: patientData.age,
            gender: patientData.gender,
            mobileNumber: patientData.mobileNumber,
            aadhaarNumber: patientData.aadhaarNumber,
            homeAddress: patientData.homeAddress,
          });
         
        } else {
          setShowAlert(true);
          setPopupmsg('Patient not found');
          setTimeout(() => {
            setShowAlert(false);
        }, 2000);

        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setShowAlert(true);
          setPopupmsg('Patient not found');
          setTimeout(() => {
            setShowAlert(false);
        }, 2000);
        } else {
          console.error('Error:', error);
        }
      }
    } else {
      try {
        const response = await axios.get(`${config.apiUrl}/patientregistration/mobile/${searchTerm}`);
        const patientData = response.data;
        console.log('dta',patientData);

        if (Array.isArray(patientData) && patientData.length > 1) {
          setInitialFormData(patientData);
          setPopupRows(patientData);
          setUpdateUhid(patientData.uhid);
          console.log('patientData.uhid)',patientData.uhid);
          setShowPopup(true);
        } 

        else if (patientData) {
          const formattedDob = formatDate(patientData[0].dob);
          setInitialFormData(patientData[0]);
          console.log('dta',patientData);

          setFormData({
            ...formData,
            title: patientData[0].title,
            firstName: patientData[0].firstName,
            lastName: patientData[0].lastName,
            fatherName: patientData[0].fatherName,
            dob: formattedDob,
            age: patientData[0].age,
            gender: patientData[0].gender,
            mobileNumber: patientData[0].mobileNumber,
            aadhaarNumber: patientData[0].aadhaarNumber,
            homeAddress: patientData[0].homeAddress
          });
          setUpdateUhid(patientData[0].uhid);
             
        } 
        else {
          setShowAlert(true);
          setPopupmsg('Patient not found');
          setTimeout(() => {
            setShowAlert(false);
        }, 2000);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setShowAlert(true);
          setPopupmsg('Patient not found');
          setTimeout(() => {
            setShowAlert(false);
        }, 2000);
        } else {
          console.error('Error:', error);
        }      }
    }
    console.log("Updated Form Data:", formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = [
      "title",
      "firstName",
      "lastName",
      "fatherName",
      "dob",
      "age",
      "gender",
      "mobileNumber",
      "aadhaarNumber",
      "homeAddress"
  ];

  const emptyFields = requiredFields.filter(field => !formData[field]);
  if (emptyFields.length > 0) {
      setHighlightedFields(emptyFields);

      setTimeout(() => {
          setHighlightedFields([]);
      }, 3000);
      return;
  }

  const isDataEdited = Object.keys(formData).some(field => {
    return formData[field] !== initialFormData[field];
  });
  
  if (!isDataEdited) {
    setShowAlert(true);
    setPopupmsg('No data edited');
    setTimeout(() => {
        setShowAlert(false);
    }, 2000);
  } 
    try {
      setSubmitting(true);
      let response;
      if (formData.search) {
        const editedFields = requiredFields.filter(field => formData[field] !== initialFormData[field]);

        if (editedFields.length > 1 ) {
          if(isNaN(formData.search)){
          response = await axios.put(`${config.apiUrl}/patientregistration/${formData.search}`, formData);
          setShowSuccessMessage(true);
          setPopupmsg("Updated Successfully")
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 2000);}else{
            response = await axios.put(`${config.apiUrl}/patientregistration/${updateuhid}`, formData);
            setShowSuccessMessage(true);
            setPopupmsg("Updated Successfully")
            setTimeout(() => {
              setShowSuccessMessage(false);
            }, 2000);
          }
        } else {
          setShowAlert(true);
        
          setPopupmsg('No data edited');
          setTimeout(() => {
              setShowAlert(false);
          }, 2000);
        }
      } else {
        response = await axios.post(`${config.apiUrl}/patientregistration`, formData);
        setShowSuccessMessage(true);
        setPopupmsg('Registration Done Successfully')
  
        setTimeout(() => {
            setShowSuccessMessage(false);
        }, 3000);
      }

      if (response.data.message === "Data saved successfully" || response.data.message === "Data updated successfully") {
        setShowIdCard(true);
        const requestData = response.data.request;
        const idCardData = {
          uhid: response.data.uhid,
          name: requestData.title + '.' + requestData.firstName + ' ' + requestData.lastName,
          age: requestData.age,
          gender: requestData.gender,
          phoneNumber: requestData.mobileNumber
        };
        setIdCardData(idCardData);

        setFormData({
          search:'',
          title: '',
          firstName: '',
          lastName: '',
          fatherName: '',
          dob: '',
          age: '',
          gender: '',
          mobileNumber: '',
          aadhaarNumber: '',
          homeAddress: ''
        });
        setUpdateUhid('');
        setShowSuccessMessage(true);
        setShowIdCard(true);

        setTimeout(() => {
            setShowSuccessMessage(false);
        }, 2000);
      } else {
        console.error("Error saving data:", response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data === 'Aadhaar number already exists') {
          setShowAlert(true);
          setPopupmsg('Aadhaar number already exists');
          setTimeout(() => {
              setShowAlert(false);
          }, 2000);
      } else if (error.response) {
          console.error("Error submitting form:", error.response.data);
      } else {
          console.error("Error submitting form:", error);
      }
      setSubmitting(false);
  } finally {
      setSubmitting(false);
    }
  
};



  const handleRowSelect = (row) => {
    const formattedDob = formatDate(row.dob);

    setFormData({
      ...formData,
      title: row.title,
      firstName: row.firstName,
      lastName: row.lastName,
      fatherName: row.fatherName,
      dob: formattedDob,
      age: row.age,
      gender: row.gender,
      mobileNumber: row.mobileNumber,
      aadhaarNumber: row.aadhaarNumber,
      homeAddress: row.homeAddress
    });
    setUpdateUhid(row.uhid);

    setShowPopup(false); 
  };

  const handleClose = () => {
    setShowIdCard(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "ID Card",
    pageStyle: `
    @page {
      size: 3.5in 2in; /* Visiting card size */
      margin: 0; /* No margins */
    }
    
    @media print {
      body {
        margin: 0; /* No body margins */
      }
    }
    
    `,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "mobileNumber") {
      newValue = value.replace(/\D/g, "").slice(0, 10);
    }
    if (name === "aadhaarNumber") {
      newValue = value.replace(/\D/g, "").slice(0, 12);
    }

    if (name === "firstName" || name === "lastName" || name === "fatherName") {
      newValue = value.replace(/[^a-zA-Z]/g, "");
    }

    setFormData({ ...formData, [name]: newValue });
    setHighlightedFields((prevFields) => prevFields.filter((field) => field !== name));

    if (name === "dob") {
      const dobDate = new Date(value);
      const today = new Date();
      let age = Math.floor((today - dobDate) / (365.25 * 24 * 60 * 60 * 1000));
      setFormData({ ...formData, [name]: value, age });
    } else {
      setFormData({ ...formData, [name]: newValue });
    }
  };

  const handleUHIDSelection = (e) => {
    setSelectedUHID(e.target.value);
  };

  const getMinimumDate = () => {
    const today = new Date();
    const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(today.getFullYear() - 1);
    return oneYearAgo.toISOString().split('T')[0];
  }

  return (
    <div className="container-fluid mt-3">
      <style>
        {
          `.custom-alert {
           width: 20%; 
           z-index: 3; 
           top: 0;
           left: 50%;
           transform: translateX(-50%);
         }
        
         .highlighted {
           border: 1px solid red;
       }
       .custom-modal {
           max-width: 1000px; 
           min-height: 400px; 
       }
       .datatable {
           display: flex;
           align-items: center;
           justify-content: center;
           position: fixed;
           z-index: 9999;
           top: 0;
           left: 0;
           width: 100%;
           height: 100%;
           background-color: rgba(0, 0, 0, 0.5); 
         }
         
         /* Modal content */
         .datatable-content {
           background-color: #fefefe;
           width:50%;
           padding: 20px;
           border-radius: 5px;
           box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
           position: relative; 
         }

         
         /* Close button */
         .datatable-close {
           color: #aaa;
           position: absolute;
           top: 5px;
           right: 10px;
           font-size: 28px;
           font-weight: bold;
           cursor: pointer;
         }
         
         .datatable-close:hover,
         .datatable-close:focus {
           color: black;
           text-decoration: none;
         }
         
         .datatable-content {
           animation: slideIn 0.5s forwards;
         }
         
         @keyframes slideIn {
           from {
             transform: translateY(-100%);
             opacity: 0;
           }
           to {
             transform: translateY(0);
             opacity: 1;
           }
         }
         @media (max-width: 767px) {
           .custom-alert,
           .custom-modal,
           .datatable {
             max-width: 100%; 
           }

           .custom-alert{
            width:30%
           }
           
     
           .datatable-content {
             width: 100%; /* Make the modal content full width on small screens */
           }
     
               /* Add more styles as needed for other elements on small screens */
             }

             @media(max-width: 600px){

              .custom-alert{
                width:60%
               }
               .idcard{
                width:80vw;
              
               }
               .custom-table-style td,
            .custom-table-style th {
              font-size:8px;
            }

            

             
             }
    
             .custom-table-style {
              border-collapse: collapse;
            }
            
            .custom-table-style td,
            .custom-table-style th {
              border: none !important;
              font-size:12px;
            }

           
            
    `
        }
      </style>
      {showPopup && (
            <div className="modal datatable">
              <div className="modal-content datatable-content" style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="close datatable-close" onClick={() => setShowPopup(false)}>&times;</span>
                <div className="mt-4">
                  <TablePopup rows={popupRows} onSelect={handleRowSelect} />
                </div>
              </div>
            </div>
          )}
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
        <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </symbol>
        <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
        </symbol>
        <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </symbol>
      </svg>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7 col-xs-7">
            <h2><b>Registration Form</b></h2>
          </div>

          <div className=" col-lg-4 col-md-5 col-xs-5">
    <div className="input-group mb-3">
    <h5 className="me-2 mt-1">Search</h5> 
        <input
            type="text"
            placeholder="UHID or Mobile No"
            name="search"
            size="sm"
            id="search"
            value={formData.search}
            onChange={handleChange}
            list="uhidOptions"
            className="searchinput form-control me-2 rounded"
            onBlur={handleUHIDSelection}
            onKeyDown={handleKeyDown}
        />
        <datalist id="uhidOptions">
            {uhidOptions.map((option, index) => (
                <option key={index} value={option} />
            ))}
        </datalist>
        <div className="input-group-append">
            <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={handleSearch}
            >
                <FontAwesomeIcon icon={faSearch} />
            </button>
        </div>
    </div>
</div>


        </div>

       

      </div>
      <div className="bg-white border ps-4 pe-4 pb-2 m-2">

        <form onSubmit={handleSubmit}>
          <div className="row mt-5">
            <div className="col-md-2">
              <div className="mb-3">
                <b><label htmlFor="title" className="form-label">Title</label></b>
                <select className="form-select form-select-sm" id="title" name="title" value={formData.title} onChange={handleChange} required>
                  <option value="">Select Title</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <b><label htmlFor="firstName" className="form-label">First Name</label></b>
                <input type="text" className="form-control" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} required />
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <b><label htmlFor="lastName" className="form-label">Last Name</label></b>
                <input type="text" className="form-control" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} required />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <b><label htmlFor="fatherName" className="form-label">Father's Name</label></b>
                <input type="text" className="form-control" id="fatherName" name="fatherName" value={formData.fatherName} onChange={handleChange} required />
              </div>
            </div>
          </div>



          <div className="row">
            <div className="col-md-4">
              <div className="mb-3">
                <b><label htmlFor="dob" className="form-label">Date of Birth</label></b>
                <input
                  type="date"
                  className="form-control"
                  id="dob"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                  max={getMinimumDate()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <b><label htmlFor="age" className="form-label">Age</label></b>
                <input type="number" className="form-control" id="age" name="age" value={formData.age} onChange={handleChange} disabled required />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <b><label htmlFor="gender" className="form-label">Gender</label></b>
                <select className="form-select" id="gender" name="gender" value={formData.gender} onChange={handleChange} required>

                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="transgender">Transgender</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="mb-3">
                <b><label htmlFor="mobileNumber" className="form-label">Mobile Number</label></b>
                <input type="tel" pattern="[0-9]{10}" className="form-control" id="mobileNumber" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} required />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <b><label htmlFor="aadhaarNumber" className="form-label">Aadhaar Number</label></b>
                <input
                  type="text"
                  pattern="[0-9]{12}"
                  className="form-control"
                  id="aadhaarNumber"
                  name="aadhaarNumber"
                  value={formData.aadhaarNumber}
                  onChange={handleChange}
                  required
                  readOnly={!!formData.search} // Set readOnly based on the existence of formData.uhid
                />              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <b><label htmlFor="homeAddress" className="form-label">Home Address</label></b>
                <textarea className="form-control" id="homeAddress" name="homeAddress" value={formData.homeAddress} onChange={handleChange} required />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-end mb-3">
              <button type="submit" className="btn me-4" onClick={handlecancel} style={{ backgroundColor: "rgb(72, 194, 205)", color: "white" }} > Cancel </button>
              <button type="submit" disabled={submitting} className="btn " style={{ backgroundColor: "rgb(72, 194, 205)", color: "white" }} > {submitting ? 'Submitting...' : 'Submit'}</button>
            </div>

          </div>
        </form>

        {showIdCard && (
  <div className="overlay " >
    <div className={`fixed inset-0 overflow-y-auto ${showIdCard ? "backdrop-filter backdrop-blur-sm" : ""} transition-all duration-300 ${showIdCard ? "opacity-100" : "opacity-0 pointer-events-none"}`}>
      <div className="idcard container-fluid position-fixed top-0 start-0 end-0 bottom-0 bg-white border border-2 border-primary rounded shadow-lg print-styles " style={{ width: "90%", maxWidth: "350px", margin: "auto", maxHeight:'35vh', overflowY: "auto" }}>
        <button className="text-danger cursor-pointer bg-transparent border-0 "style={{float:'right'}}  onClick={handleClose}>&#10006;</button>
        <div  classname='print-container ' ref={componentRef}>
          <div className="container-fluid">
            <div classname='row'>
              <div className="col-12">
        <div className="text-center mt-2 d-flex">
          <img className="logo" src={logo} alt="Hospital Logo" style={{ width:'30px',height:'30px',  borderRadius: "50%" }} />
          <h4 style={{ color: 'rgb(40, 204, 205)',marginLeft:'3px'}}>Ocean Dental Clinic</h4>
        </div>
        </div>
        </div>
        </div>
       
        <div className="container-fluid idtable">
          <div className="row">
            <div className="col-9">
              <div className="table-responsive">
                <table className="table custom-table-style">
                  <tbody>
                    <tr>
                      <td className="text-gray-700 text-start p-1"><b>UHID</b></td>
                      <td className="p-1"><b>{idCardData.uhid}</b></td>
                    </tr>
                    <tr>
                      <td className="text-gray-700 text-start p-1"><b>Name</b></td>
                      <td className="p-1"><b>{idCardData.name}</b></td>
                    </tr>
                    <tr>
                      <td className="text-gray-700  text-start p-1"><b>Age</b></td>
                      <td className="p-1"><b>{idCardData.age}</b></td>
                    </tr>
                    <tr>
                      <td className="text-gray-700 text-start p-1"><b>Gender</b></td>
                      <td className="p-1"><b>{idCardData.gender}</b></td>
                    </tr>
                    <tr>
                      <td className="text-gray-700 text-start p-1"><b>Mobile No</b></td>
                      <td className="p-1"><b>{idCardData.phoneNumber}</b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-3">
            <img
                            src={profile}
                            alt="not found"
                            style={{
                              width: "60px",
                              height: "60px",
                              borderRadius: "50%",
                              
                            }}
                          />
            </div>
          </div>
        </div>
        </div>
        <button  className='rounded' style={{ backgroundColor: "rgb(72, 194, 205)",fontSize:'10px', color: "white",float: "right", marginBottom:'2px' }} onClick={handlePrint}>Print</button>
      </div>
    </div>
  </div>
)}

      </div>

      {showAlert && (
              <div className="position-absolute alert alert-danger d-flex align-items-center custom-alert" role="alert" style={{top:'14%', left:'40'}}>
                <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                  <use href="#exclamation-triangle-fill" />
                </svg>
                <div>
                {popupmsg}
                </div>
              </div>
            )}

            {showSuccessMessage && (
              <div className="position-absolute alert alert-success d-flex align-items-center custom-alert" role="alert" style={{top:'14%', left:'40'}}>
                <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:">
                  <use href="#check-circle-fill" />
                </svg>
                <div>
                  {popupmsg}
                </div>
              </div>
            )}
    </div>
  );
};

export default RegistrationForm;
