import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faHistory  } from '@fortawesome/free-solid-svg-icons';
import config from '../config';
import { faTimes, faPrescriptionBottleAlt } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import PrescriptionHistory from './PrescriptionHistory';
import TablePopup from './PopupTable';
import bg from "../logo/bgtemplate.jpg"
import pdfbg from "../logo/template.png"


const PrintScreen = ({ patientDetails, prescriptionData, onClose }) => {
  const genderText = patientDetails.gender
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintClick = () => {
    handlePrint();
  };

  return (
    <>
    
      <div className="d-flex justify-content-end mb-4">
        <FontAwesomeIcon
          icon={faTimes}
          onClick={onClose}
          className="text-danger cursor-pointer fs-6"
        />
      </div>

      <div className="border border-3 border-gray-300 p-4 rounded-3">
        <div
          className="registration-content p-4"
          style={{
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            fontFamily: 'font-italic', 
          }}
          ref={componentRef}
        >
         <div className="text-center">
  <h2 className="fs-10 fw-bold mb-2 text-primary">
    OCEAN DENTAL CARE
  </h2>
  <p className="fs-5 fw-bold mb-2">
    110-114, 1st Floor-PTR Sweets, P.P.Chavadi, Theni Main Road{' '}
  </p>
  <p className="fs-6 fw-bold mb-2">
    Thirumalai Colony Main, Madurai - 625 016{' '}
  </p>
  <p className="fs-9 fw-bold mb-4">
    Phno : +91 7305150160, 0452-3559075 <br></br> E-mail: odc@gmail.com{' '}
  </p>
</div>

          <div>
            <div className="d-flex justify-content-center align-items-center fs-5 fw-bold pb-4 text-success ">
              DESCRIPTION
            </div>

            <div className="d-flex justify-content-between">
  <div className="me-5">
    <p className="fw-bold">
      <span className="d-inline-block w-80">Name :</span> {patientDetails.patientName}
    </p>
    <p className="fw-bold">
      <span className="d-inline-block w-80">UHID :</span> {patientDetails.uhid}
    </p>
    <p className="fw-bold">
      <span className="d-inline-block w-80">Mobile Number :</span>{' '}
      {patientDetails.mobileNumber}
    </p>
  </div>

  <div className="">
    <p className="fw-bold">
      <span className="d-inline-block w-80">Age :</span> {patientDetails.age}
    </p>
    <p className="fw-bold">
      <span className="d-inline-block w-80">Gender :</span> {genderText}
    </p>
  </div>
</div>

          </div>
          {prescriptionData.length > 0 && (
  <table className="w-100 mt-4  table table-info"
  style={{ border: '1px solid #000', important: 'true' }}>

    <thead className="bg-primary text-white">
      <tr>
        <th
          colSpan="5"
          className="fs-5 fw-bold py-2 ps-4 border-bottom border-gray-900 text-center"
        >
          Prescription Details
        </th>
      </tr>
      <tr>
        <th className="ps-4 py-2 border-bottom border-gray-900 text-start">
          S.No
        </th>
        <th className="ps-4 py-2 border-bottom border-gray-300 text-start">
          Medicine Name
        </th>
        <th className="ps-4 py-2 border-bottom border-gray-300 text-start">
          Brand
        </th>
        <th className="ps-4 py-2 border-bottom border-gray-300 text-start">
          Frequency
        </th>
        <th className="ps-4 py-2 border-bottom border-gray-300 text-start">
          Instruction
        </th>
      </tr>
    </thead>

    <tbody>
      {prescriptionData.map((prescription, index) => (
        <tr key={index}>
          <td className="ps-4 py-2 border-bottom border-gray-300">
            {index + 1}
          </td>
          <td className="ps-4 py-2 border-bottom border-gray-300">
            {prescription.name}
          </td>
          <td className="ps-4 py-2 border-bottom border-gray-300">
            {prescription.brandname}
          </td>
          <td className="ps-4 py-2 border-bottom border-gray-300">
            {prescription.frequency}
          </td>
          <td className="ps-4 py-2 border-bottom border-gray-300">
            {prescription.instruction}
            {prescription.morning && (
              <div>
                <strong>Morning:</strong> &#10003;
              </div>
            )}
            {prescription.afternoon && (
              <div>
                <strong>Afternoon:</strong> &#10003;
              </div>
            )}
            {prescription.evening && (
              <div>
                <strong>Evening:</strong> &#10003;
              </div>
            )}
            {prescription.night && (
              <div>
                <strong>Night:</strong> &#10003;
              </div>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)}

        </div>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <button
          onClick={handlePrintClick}
          className="btn btn-primary"
        >
          Print
        </button>
      </div>
    </>
  );
};


const PatientForm = () => {
  const [formData, setFormData] = useState({
    uhid: '',
    patientName: '',
    gender: '',
    age: '',
    doctorName: 'Dr.P.Priyanka Angline B.D.S',
    mobileNumber: '',
    medicalAffliction: '',
    treatmentgiven: '',
    currentDate: new Date().toISOString().split('T')[0]
  });

  const [highlightedFields, setHighlightedFields] = useState([]);
  const [uhidOptions, setUHIDOptions] = useState([]); 
  const [submitting, setSubmitting] = useState(false); 
  const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showAlert, setShowAlert] = useState(false); 

  const [popupmsg, setPopupmsg] = useState('');
  const [selectedMedicine, setSelectedMedicine] = useState("");
  const [brandNames, setBrandNames] = useState([]);

  const [isPrintTemplateVisible, setIsPrintTemplateVisible] = useState(false);

  const [selectedUHID, setSelectedUHID] = useState('');
  const [medicineNames, setMedicineNames] = useState([]);

  const [showPopup, setShowPopup] = useState(false); 
    const [popupRows, setPopupRows] = useState([]);

const handleRowSelect = (row) => {
        setFormData({
          ...formData,
          uhid: row.uhid,
          patientName: row.firstName + " " + row.lastName,
          mobileNumber: row.mobileNumber,
          age: row.age,
          gender: row.gender
        });
      
        setShowPopup(false); 
      };

  const [selectedTimes, setSelectedTimes] = useState([]);
  const [isprescription, setIsprescription] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);
  const [prescriptionData, setPrescriptionData] = useState([]);

  const handleCheckboxClick = (index, time) => {
    const updatedSelectedTimes = [...selectedTimes];
    updatedSelectedTimes[index] = {
      ...updatedSelectedTimes[index],
      [time]: !updatedSelectedTimes[index]?.[time],
    };
    setSelectedTimes(updatedSelectedTimes);
  };

  const handleAddMedicine = () => {
    setPrescriptionData([
      ...prescriptionData,
      {
        brandname: "",
        name: "",
        frequency: "",
        instruction: "",
        time: "",
      },
    ]);
    setEmptyFields([]);
  };

  const handleOpenprescriptionmodal = () => {
    setIsprescription(true);
    setIsPrintTemplateVisible(false);
  };

  const handleClose = () => {
    setPrescriptionData([]);

    setIsPrintTemplateVisible(false);
    setIsprescription(false);
  };

  const handleCloseprescriptionmodal = () => {
    const hasEmptyRows = prescriptionData.some((prescription) =>
      Object.entries(prescription).some(([key, value]) => {
        if (key === 'time') {
          return !(prescription.morning || prescription.afternoon || prescription.evening || prescription.night);
        }
  
        const trimmedValue = typeof value === 'string' ? value.trim() : value;
        if (trimmedValue === '') {
          console.log(`Empty value found for ${key}`);
          return true; 
        }
        return false;
      })
    );
  
    if (hasEmptyRows) {
     

  setShowAlert(true);
  setPopupmsg("Please fill the complete Prescription")
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);


    } else {
      setIsPrintTemplateVisible(false);
      setIsprescription(false);
      setEmptyFields([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setHighlightedFields((prevFields) => prevFields.filter((field) => field !== name));
    setShowNotFoundMessage(false); 
  };

  const handlePatientNameChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z.\s]*$/;
    if (regex.test(value) || value === '') {
      setFormData({ ...formData, patientName: value });
    }
  };

  const handleMobileNoChange = (event) => {
    const value = event.target.value;
    const regex = /^[0-9]{0,10}$/;
    if (regex.test(value) || value === '') {
      setFormData({ ...formData, mobileNumber: value });
    }
  };

  const closeModal = () => {
    setIsPrintTemplateVisible(false);
    setIsprescription(false);
  };

  const handleAgeChange = (event) => {
    const value = event.target.value;
    const age = parseInt(value, 10);
    if (!isNaN(age) && age >= 0 && age <= 100) {
      setFormData({ ...formData, age: value });
    } else {
      setFormData({ ...formData, age: '' });
    }
  };


  const handlePrescriptionChange = (index, field, value) => {
    const updatedMedicines = [...prescriptionData];
    updatedMedicines[index][field] = value;
    setPrescriptionData(updatedMedicines);

    const updatedEmptyFields = [...emptyFields];
    if (value.trim() === "" && !updatedEmptyFields.includes(index)) {
      updatedEmptyFields.push(index);
    } else if (value.trim() !== "") {
      const indexOfField = updatedEmptyFields.indexOf(index);
      if (indexOfField !== -1) {
        updatedEmptyFields.splice(indexOfField, 1);
      }
    }
    setEmptyFields(updatedEmptyFields);
    if (field === "name") {
      setSelectedMedicine(value);
    }
  };

  const handleRemoveMedicine = (index) => {
    const updatedMedicines = [...prescriptionData];
    updatedMedicines.splice(index, 1);
    setPrescriptionData(updatedMedicines);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = [
      "patientName",
      "gender",
      "age",
      "doctorName",
      "mobileNumber",
      "medicalAffliction",
       "treatmentgiven"
    ];

    const emptyFields = requiredFields.filter(field => !formData[field]);
    if (emptyFields.length > 0) {
      setHighlightedFields(emptyFields);

      setTimeout(() => {
        setHighlightedFields([]);
      }, 3000);
      return;
    }

    try {
      setSubmitting(true);

      const response = await fetch(`${config.apiUrl}/saveprescription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uhid: formData.uhid,
          patientName: formData.patientName,
          mobileNumber: formData.mobileNumber,
          gender: formData.gender,
          age: formData.age,
          doctorName: formData.doctorName,
          medicalaffiction: formData.medicalAffliction,
          treatmentgiven: formData.treatmentgiven,
          prescription: prescriptionData,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to save form data');
      }

      const responseData = await response.json();
      setShowSuccessMessage(true);
              setPopupmsg('Doctor Consultation Done Successfully');
              setTimeout(() => {
                setShowSuccessMessage(false);
            }, 2000);

      if (response.ok) {
        setIsPrintTemplateVisible(true);
      }


    } catch (error) {
      console.error('Error saving form data:', error.message);
      setSubmitting(false);

    }
    finally {
      setSubmitting(false);
    }
  };


  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const handleCloseHistoryModal = () => setShowHistoryModal(false);
  const handleShowHistoryModal = () => setShowHistoryModal(true);

  const handleCancel = () => {
    setFormData({
      uhid: '',
      patientName: '',
      gender: '',
      age: '',
      doctorName: 'Dr.P.Priyanka Angline B.D.S',
      mobileNumber: '',
      medicalAffliction: '',
      treatmentgiven:'',
      currentDate: new Date().toISOString().split('T')[0],
      uhidSearch:''
    });
    setPrescriptionData([
      {
        brandname: "",
        name: "",
        frequency: "",
        instruction: "",
        morning: false,
        afternoon: false,
        evening: false,
        night: false,
      },
    ]);
    setSelectedUHID('')
  };

  const handleUHIDSelection = (e) => {
    setSelectedUHID(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 8) {
      setFormData({
        doctorName: 'Dr.P.Priyanka Angline B.D.S',
        patientName: '',
        mobileNumber: '',
        age: '',
        gender: '',
        medicalAffiliation: '',
        treatmentgiven: '',
        selectedDate: '',
        selectedTime: '',
        uhidSearch: ''
      });
    }
  };

  useEffect(() => {
    fetchUHIDs(); 
  }, [])

  const fetchUHIDs = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/patientregistration/listuhids`);
      setUHIDOptions(response.data);

    } catch (error) {
      console.error('Error fetching UHIDs:', error);
    }
  };

  const handleUHIDSelect = async (e) => {
    const searchTerm = selectedUHID.trim(); 
      if(searchTerm.length > 0){
        if (isNaN(searchTerm)) {
          try {
            const response = await axios.get(`${config.apiUrl}/registration/${searchTerm}`);
            const patientData = response.data;
      
            if (patientData) {
              setFormData({
                ...formData,
                uhid: patientData.uhid,
                patientName: patientData.firstName + " " + patientData.lastName,
                mobileNo: patientData.mobileNumber,
                age: patientData.age,
                gender: patientData.gender,
                mobileNumber:patientData.mobileNumber,
              });
            } else {
              setShowAlert(true);
              setPopupmsg('Patient not found');
              setTimeout(() => {
                setShowAlert(false);
            }, 2000);
    
            }
          } catch (error) {
            if (error.response && error.response.status === 404) {
              setShowAlert(true);
              setPopupmsg('Patient not found');
              setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            } else {
              console.error('Error:', error);
            }
          }
        } else {
          try {
            const response = await axios.get(`${config.apiUrl}/patientregistration/mobile/${searchTerm}`);
            const patientData = response.data;
      
            if (Array.isArray(patientData) && patientData.length > 1) {
              setPopupRows(patientData); 
              setShowPopup(true); 
            } else if (patientData) {
              setFormData({
                ...formData,
                uhid: patientData[0].uhid,
                patientName: patientData[0].firstName + " " + patientData[0].lastName,
                mobileNo: patientData[0].mobileNumber,
                age: patientData[0].age,
                gender: patientData[0].gender,
                mobileNumber:patientData[0].mobileNumber,
              });
            } else {
              setShowAlert(true);
              setPopupmsg('Patient not found');
              setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            }
          } catch (error) {
            console.error('Error searching by mobile number:', error);
            setShowAlert(true);
            setPopupmsg("Patient Not Found")
                setTimeout(() => {
                  setShowAlert(false);
                }, 2000);
          }
        }
      }
  };

  useEffect(() => {
    fetch(`${config.apiUrl}/getallstock`)
      .then((response) => response.json())
      .then((data) => {
        setMedicineNames(data);
      })
      .catch((error) => console.error("Error fetching medicine names:", error));
  }, []);

  useEffect(() => {
    if (selectedMedicine) {
      fetch(`${config.apiUrl}/allstock/${selectedMedicine}`)
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data)) {
            setBrandNames(data);
          }
        })
        .catch((error) => console.error("Error fetching brand names:", error));
    }
  }, [selectedMedicine]);



  return (
    <>
      <style>
        {`




.custom-alert {
   
  width: 30%; 
  z-index: 3; 
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.container-fluid {
  background-image: url(${bg});
  background-size: cover;
}

.highlighted {
  border: 1px solid red;
}

.blur {
  filter: blur(5px);
}

.datatable {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.datatable-content {
  background-color: #fefefe;
  width:50%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
}
.historybutton{
  left:35vw;
  border:1px solid rgb(72, 194,205);
  padding:4px;
 
}

.datatable-close {
  color: #aaa;
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.datatable-close:hover,
.datatable-close:focus {
  color: black;
  text-decoration: none;
}

.datatable-content {
  animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.form-select{
  font-size:13px;
}
.form-control{
  font-size:13px;
}

@media (max-width : 500px){
  .custom-alert{
    width: 80%; 
    left:10%;
  }
  .historybutton{
    left:70%;

  }
  .doctorname{
    text-align:start;
  }
  Form {
    font-size:12px;
  }
  .form-select{
    font-size:10px;
  }
  .form-control{
    font-size:10px;
  }
}
@media (max-width: 345px){
  Form{
    font-size:10px;
  }
  .prescriptiontable{
    font-size:10px;
  }
  .form-select{
    font-size:10px;
  }
  .form-control{
    font-size:10px;
  }
}

@media (max-width: 770px) {
  .form-select{
    font-size:12px;
  }
  .form-control{
    font-size:12px;
  }

  .container-fluid {
    background-image: none;
  }
  .datatable-content{
    width:100%;
  }

  .content {
    padding: 10px;
  }
  .uhid-search-col {
    text-align: left;
  }
}

    `}
      </style>
      <div className="container-fluid  mt-3">
     
      <div className={`content ${showHistoryModal ? "blur" : ""}`}>

            <Row className="mb-2 align-items-center">
            <Col lg={6} md={6} xs={12} className='d-flex justify-content-between postion-relative'>
                <h2><b>Description Form</b></h2>
                <button  className='rounded position-absolute historybutton ' style={{ backgroundColor: "rgb(72, 194,205)", color: "white" }} onClick={handleShowHistoryModal} ><FontAwesomeIcon icon={faHistory} style={{ cursor: "pointer"}} />History</button>
            </Col>
            <Col lg={5} md={6} xs={12} className='mt-2 text-lg-end text-md-start'>
                <div className="input-group">
                    <h5 className='mt-1' ><b >Search</b></h5>
                    <input
                        type="text"
                        placeholder="Search by UHID or Mobile No"
                        name="uhidSearch"
                        value={formData.uhidSearch}
                        onChange={handleChange}
                        list="uhidOptions"
                        className="form-control me-2 ms-2 rounded"
                        onBlur={handleUHIDSelection}
                        onKeyDown={handleKeyDown}
                    />
                    <datalist id="uhidOptions">
                        {uhidOptions.map((option, index) => (
                            <option key={index} value={option} />
                        ))}
                    </datalist>
                    <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={handleUHIDSelect}
                    >
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>
            </Col>
        </Row>
        
    
    

      <div className="row">
          <div className="col-md-6 offset-md-3">

          <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
        <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </symbol>
        <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
        </symbol>
        <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </symbol>
      </svg>

            {showAlert && (
              <div className="position-absolute alert alert-danger  d-flex align-items-center custom-alert" role="alert" style={{top:'15%',left:'45%'}}>
                <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                  <use href="#exclamation-triangle-fill" />
                </svg>
                <div>
                {popupmsg}
                </div>
              </div>
            )}

            {showSuccessMessage && (
              <div className="position-absolute alert alert-success d-flex align-items-center custom-alert" role="alert">
                <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:">
                  <use href="#check-circle-fill" />
                </svg>
                <div>
                  {popupmsg}
                </div>
              </div>
            )}
           
  
          </div>
        </div>

       

        <div className="bg-white border ps-2 pe-2 pb-2 m-2">

          <Form onSubmit={handleSubmit}>
            <div className="row">
            <Row className="mt-3"><Col className="mb-2 doctorname"><p>DOCTOR NAME : <strong>Dr.P.Priyanka Angline B.D.S</strong></p></Col></Row>

              <div className="col-md-4">

                <Form.Group controlId="patientName" className='mt-2'>
                  <Form.Label><b>Patient Name</b></Form.Label>
                  <Form.Control
                    type="text"
                    name="patientName"
                    value={formData.patientName}
                    onChange={handlePatientNameChange}
                    className={highlightedFields.includes("patientName") ? "highlighted" : ""}

                  />
                </Form.Group>

                <Form.Group controlId="age" className='mt-2'>
                  <Form.Label><b>Age</b></Form.Label>
                  <Form.Control
                    type="number"
                    name="age"
                    value={formData.age}
                    onChange={handleAgeChange}
                    className={highlightedFields.includes("age") ? "highlighted" : ""}
                  />
                </Form.Group>


              </div>

              <div className="col-md-4">

              <Form.Group controlId="mobileNumber" className='mt-2'>
                  <Form.Label><b>Mobile No</b></Form.Label>
                  <Form.Control
                    type="tel"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleMobileNoChange}
                    className={highlightedFields.includes("mobileNumber") ? "highlighted" : ""}
                  />
                </Form.Group>

                <Form.Group controlId="gender" className='mt-2' >
                  <Form.Label><b>Gender</b></Form.Label>
                  <Form.Control
                    as="select"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className={highlightedFields.includes("gender") ? "highlighted" : ""}

                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Form.Control>
                </Form.Group>

              </div>

              <div className="col-md-4" >
              <Form.Group controlId="currentDate" className='mt-2'>
                  <Form.Label><b>Date</b></Form.Label>
                  <Form.Control
                    type="text"
                    name="currentDate"
                    value={formData.currentDate}
                    className={highlightedFields.includes("currentDate") ? "highlighted" : ""}
                  />
                </Form.Group>

                

                <Form.Group controlId="medicalAffliction" className='mt-2'>
                  <Form.Label><b>Current Dental Challenges</b></Form.Label>
                  <Form.Control
                    as="textarea"
                    name="medicalAffliction"
                    value={formData.medicalAffliction}
                    onChange={handleChange}
                    rows={1}
                    className={highlightedFields.includes("medicalAffliction") ? "highlighted" : ""}
                  />
                </Form.Group>

                <Form.Group controlId="treatmentgiven" className='mt-2'>
                  <Form.Label><b>Treatment Given</b></Form.Label>
                  <Form.Control
                    as="textarea"
                    name="treatmentgiven"
                    value={formData.treatmentgiven}
                    onChange={handleChange}
                    rows={3}
                    className={highlightedFields.includes("treatmentgiven") ? "highlighted" : ""}
                  />
                </Form.Group>

              </div>
            </div>

            <Button onClick={handleOpenprescriptionmodal} className="rounded-md btn btn-primary me-2 mt-2 mb-2">
              <FontAwesomeIcon icon={faPrescriptionBottleAlt} className="me-2" />
              <b>Prescription</b>
            </Button>

           

            <Row >
              <Col xs="auto" className="ms-auto">
                <Button type="button" onClick={handleCancel} style={{ backgroundColor: "rgb(72, 194,205)", color: "white" }}>
                  Cancel
                </Button>
              </Col>
              <Col xs="auto">
                <Button type="submit" disabled={submitting} style={{ backgroundColor: "rgb(72, 194,205)", color: "white" }}>
                  {submitting ? 'Submitting...' : 'Submit'}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        </div>


        {isprescription && (
  <div className={`modal ${isprescription ? "show" : ""}`} style={{ display: isprescription ? "block" : "none" }}>
    <div className="modal-dialog modal-dialog-centered modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-primary">Prescription</h5>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
        </div>
        <div className="modal-body">
          <div className="table-responsive prescriptiontable">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center">Medicine Name & Dosage</th>
                  <th className="text-center">Brand</th>
                  <th style={{width:'10%'}} className="text-centers">No. of Days</th>
                  <th className="text-center">Instruction</th>
                  <th colSpan="4" className="text-center">Time Schedule</th>
                  <th className="text-center">Action</th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th className="text-center">Morning</th>
                  <th className="text-center">Afternoon</th>
                  <th className="text-center">Evening</th>
                  <th className="text-center">Night</th>
                  <th></th>
                </tr>
              </thead>
              <tbody >
                      {prescriptionData.map((prescription, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              className={`form-control mt-1 p-1 border rounded w-full ${emptyFields.includes(index) &&
                                  prescriptionData[index].name.trim() === ""
                                  ? "highlight-input": ""
                                }`}
                              list="medicineNames"
                              placeholder="Enter Medicine Name"
                              value={prescriptionData[index].name}
                              onChange={(e) => handlePrescriptionChange(index, "name", e.target.value)}
                            />
                            <datalist id="medicineNames">
                              {medicineNames.map((name, i) => (
                                <option key={i}
                                  value={`${name.medicinename} ${name.dosage}`}
                                />
                              ))}
                            </datalist>
                          </td>
                          <td>
                            <input
                              type="text"
                              className={`form-control mt-1 p-1 border rounded w-full ${emptyFields.includes(index) && prescriptionData[index].brandname.trim() === "" ? "highlight-input" : ""}`}
                              list="brandname"
                              placeholder="Enter brand Name"
                              value={prescriptionData[index].brandname || ""}
                              onChange={(e) => handlePrescriptionChange(index, "brandname", e.target.value)}
                            />
                            <datalist id="brandname">
                              {brandNames.map((suggestion, index) => (
                                <option
                                  key={index}
                                  value={suggestion.brandname}
                                />
                              ))}
                            </datalist>
                          </td>
                          <td>
                            <input
                              type="number"
                              className={`form-control mt-1 p-1 border rounded w-full ${emptyFields.includes(index) && prescriptionData[index].frequency.trim() === "" ? "highlight-input" : ""}`}
                              list="Frequency"
                              placeholder="Enter Frequency"
                              value={prescriptionData[index].frequency}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === "" || (parseInt(inputValue) !== 0 && !isNaN(parseInt(inputValue)))) {
                                  handlePrescriptionChange(index, "frequency", inputValue);
                                }
                              }}
                              min="1" 
                              step="any" 
                            />
                          </td>
                          <td>
                            <select
                              className={`form-select mt-1 p-1 border rounded w-full ${emptyFields.includes(index) && prescription.instruction.trim() === "" ? "highlight-input" : ""}`}
                              style={{ backgroundColor: "white"}}
                              value={prescription.instruction}
                              onChange={(e) => {
                                const updatedMedicines = [...prescriptionData];
                                updatedMedicines[index].instruction = e.target.value;
                                setPrescriptionData(updatedMedicines);
                              }}
                            >
                              <option value="">Select</option>
                              <option value="Before meal">Before meal</option>
                              <option value="After meal">After meal</option>
                            </select>
                          </td>
                          <td className="text-center cursor-pointer" onClick={() => handleCheckboxClick(index, "morning")}>
                            <input
                              type="checkbox"
                              checked={prescription.morning}
                              onChange={(e) => {
                                const updatedMedicines = [...prescriptionData];
                                updatedMedicines[index].morning = e.target.checked;
                                setPrescriptionData(updatedMedicines);
                              }}
                            />
                          </td>
                          <td className="text-center cursor-pointer" onClick={() => handleCheckboxClick(index, "afternoon")}>
                            <input
                              type="checkbox"
                              checked={prescription.afternoon}
                              onChange={(e) => {
                                const updatedMedicines = [...prescriptionData];
                                updatedMedicines[index].afternoon = e.target.checked;
                                setPrescriptionData(updatedMedicines);
                              }}
                            />
                          </td>
                          <td className="text-center cursor-pointer" onClick={() => handleCheckboxClick(index, "evening")}>
                            <input
                              type="checkbox"
                              checked={prescription.evening}
                              onChange={(e) => {
                                const updatedMedicines = [...prescriptionData];
                                updatedMedicines[index].evening = e.target.checked;
                                setPrescriptionData(updatedMedicines);
                              }}
                            />
                          </td>
                          <td className="text-center cursor-pointer" onClick={() => handleCheckboxClick(index, "night")}>
                            <input
                              type="checkbox"
                              checked={prescription.night}
                              onChange={(e) => {
                                const updatedMedicines = [...prescriptionData];
                                updatedMedicines[index].night = e.target.checked;
                                setPrescriptionData(updatedMedicines);
                              }}
                            />
                          </td>
                          <td className="cursor-pointer text-center" onClick={() => handleRemoveMedicine(index)}>
                            <FontAwesomeIcon icon={faTimes} style={{ color: "red", fontSize: "1.2rem" }} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <button onClick={handleAddMedicine} className="btn mt-4" style={{ backgroundColor: "rgb(72, 194,205)", color: "white" }}>Add Medicine</button>
            <button type="button" className="btn mt-4" style={{ backgroundColor: "rgb(72, 194,205)", color: "white" }} onClick={handleCloseprescriptionmodal}>OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
)}


        {isPrintTemplateVisible && (
          <div className="modal d-block">
            <div
              className={`modal-dialog modal-dialog-centered modal-lg ${isPrintTemplateVisible ? "show" : ""
                }`}
            >
              <div
                className={`modal-content ${isPrintTemplateVisible ? "scale-1" : "scale-0"
                  }`}
              >
                <div className="modal-body">
                  <PrintScreen
                    patientDetails={{ ...formData }}
                    prescriptionData={prescriptionData}
                    selectedUHID={selectedUHID}
                    onClose={() => {
                      closeModal();
                      handleCancel();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

{showHistoryModal && (
    <div className="modal d-block" >
      <div className={`modal-dialog modal-dialog-centered modal-xl ${showHistoryModal ? "show" : ""}`}  >
        <div className={`modal-content ${showHistoryModal ? "scale-1" : "scale-0"}`} style={{height:'500px'}}>
          <div className="d-flex justify-content-end mb-4">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={handleCloseHistoryModal}
              className="text-red-500 cursor-pointer fs-6 mr-2" 
              style={{ marginTop: "15px", marginRight: "12px"}} 

              />
          </div>
          <PrescriptionHistory/>
        </div>
      </div>
    </div>
  )}

{showPopup && (
  <div className="modal datatable">
    <div className="modal-content datatable-content" style={{ display: 'flex', flexDirection: 'column' }}>
      <span className="close datatable-close" onClick={() => setShowPopup(false)}>&times;</span>
      <div className="mt-4">
        <TablePopup rows={popupRows} onSelect={handleRowSelect} />
      </div>
    </div>
  </div>
)}


      </div>
      
    </>
  );
};

export default PatientForm;