import React from 'react';

function TablePopup({ rows, onSelect }) {
  return (
    <div className="table-popup">
      <style >{`
      // media(max-width: 666px){
      //   .popuptable{
      //     width:80vw;
      //   }
      // }
      
      `}</style>
      <div className="table-responsive popuptable" >
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Patient Name</th>
              <th>UHID</th>
              <th>Mobile No</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {rows.map(row => (
              <tr key={row.uhid}>
                <td>{row.firstName} {row.lastName}</td>
                <td>{row.uhid}</td>
                <td>{row.mobileNumber}</td>
                <td>
                  <img
                    src="https://cdn-icons-png.flaticon.com/128/5610/5610944.png"
                    alt="Report Icon"
                    style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                    onClick={() => onSelect(row)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TablePopup;
