import React,{useState} from 'react';
import { Carousel } from 'react-bootstrap';

import '../styles/home.css'

const Home = () => {

    const [isPaused, setIsPaused] = useState(false);

    const handleMouseEnter = () => {
        setIsPaused(true);
    };

    const handleMouseLeave = () => {
        setIsPaused(false);
    };
    return (
        <>
      

        <div className='image-home h-screen'>
            
            {/* <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={bg1}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={bg2}
                        alt="Second slide"
                    />
                </Carousel.Item>
            </Carousel> */}

            <div className='image-home-slider'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ animationPlayState: isPaused ? 'paused' : 'running' }}></div>

            {/* <Switch>
                <Route path="/billing">
                    <Billing />
                </Route>
                <Route path="/stock">
                    <StockDetailsPage />
                </Route>
                <Route path="/purchase">
                    <Purchase />
                </Route>
            </Switch> */}
        </div>
        </>
    );
};

export default Home;