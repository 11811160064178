import React from 'react';
import { Navbar, Nav, Carousel, Dropdown } from 'react-bootstrap';
import logo from "../logo/logo - Copy.jpeg";
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'; 

const NavigationBar = () => {

    return (
        <>
         <style>
            {
                `
                .head{
                  color:blue;
                    margintop:20px;
                    font-size:32px;
                }
                .dropdown-menu {
                    display: none;
                    opacity: 0;
                    
                    transform: translateY(-10px);
                    transition: opacity 0.3s ease, transform 0.3s ease;
                }
                
                .nav-item.dropdown:hover .dropdown-menu {
                    display: block;
                    opacity: 1;
                    left:40%;
                    transform: translateY(0);
                }
            
                .nav-link-hover:hover {
                    color: rgb(0, 173, 237);
                    cursor: pointer;
                }
            
                .nav-link-hover {
                    transition: color 0.3s ease;
                }
            
                .nav-item.dropdown:hover .dropdown-menu {
                    display: block;
                    opacity: 1;
                    transform: translateY(0);
                    transition: opacity 0.3s ease, transform 0.3s ease;
                }
                
                
                @media (max-width: 770px) {
                    /* Decrease the font-size and padding for the toggle button on smaller screens */
                    .navbar-toggler {
                      font-size: 1rem;
                      padding: 8px 10px;
                    }
                  
                    /* Decrease font-size for nav links on smaller screens */
                    .navbar-nav .nav-link {
                      font-size: 1rem;
                    }
                    /* Center the collapsed navbar items */
                    .navbar-collapse {
                        text-align: center;
                        position: absolute;
                        top: 60px; /* Adjust as needed */
                        
                        right: 0;
                        width:40%;
                        z-index: 1000; /* Make sure it's above other content */
                        background-color: #fff; /* Set background color as needed */
                        box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
                      }
                  
                    /* Adjust styles for individual collapsed items */
                    .navbar-collapse .nav-item {
                      display: block;
                      margin-bottom: 10px; /* Adjust as needed */
                    }
                    .running-text-container {
                        width: 100%; /* Ensure the container takes the full width */
                        overflow: hidden;  
                        position: absolute;
        top: 62px; /* Adjust as needed */
        left: 0;  
                      }
    
                    .running-text {
                        white-space: nowrap;
                        color: #3498db; /* Change the color as needed */
                        font-size:13px; /* Adjust the font size */
                        animation: runningText 20s linear infinite; /* Adjust the duration as needed */
                      }
            
                      @keyframes runningText {
                        0% {
                          transform: translateX(100%);
                        }
                        100% {
                          transform: translateX(-150%);
                        }
                      }
                  }

                  @media (max-width: 600px) {
                    .navbar-toggler {
              font-size: 0.7rem; // Adjust the font size as needed
              padding: 6px 8px; // Adjust the padding as needed
            }
                    .head{
                        font-size:18px;
                    
                    }
                    .navbar-brand img {
                        width: 30px; /* Adjust the width as needed */
                        height: 30px; /* Adjust the height as needed */
                      }
                    .navbar-collapse {
                        text-align: center;
                        position: absolute;
                        top: 60px; /* Adjust as needed */
                        left:0;
                        right: 0;
                        width:100%;
                        z-index: 1000; /* Make sure it's above other content */
                        background-color: #fff; /* Set background color as needed */
                        box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
                      }
                      .dropdown-menu {
                        z-index: 1100; /* Set a higher value than the sticky table header */
                    }
                     .navbar {
                        z-index: 1200; /* Set a higher value than the sticky table header */
                    }
                    
                  }
                  @media (min-width: 800px){
                  .running-text-container {
                    width: 100%; /* Ensure the container takes the full width */
                    overflow: hidden;  
      
                  }

                .running-text {
                    white-space: nowrap;
                    color: #3498db; /* Change the color as needed */
                    font-size:13px; /* Adjust the font size */
                    animation: runningText 20s linear infinite; /* Adjust the duration as needed */
                  }
        
                  @keyframes runningText {
                    0% {
                      transform: translateX(100%);
                    }
                    100% {
                      transform: translateX(-150%);
                    }
                  }
                }
              }

                
               `
            }
        </style>
        
        <div className='h-screen'>
            <Navbar bg="white" variant="light" expand="xl" className="navbar-expand-lg-xl" style={{ height: '78px' }}>
                <Navbar.Brand href="/home" className=' mt-2 nav-link-hover'>
                    <img
                        src={logo}
                        width="50"
                        height="50"
                        className="d-inline-block align-top ms-3"
                        alt="Dental Clinic Logo"
                    />{' '}
                    <b className='head'>OCEAN DENTAL CLINIC</b>
                    <div className="running-text-container">
            <div className="running-text">
              110-114, 1st Floor-PTR Sweets, P.P.Chavadi, Theni Main Road,
              Thiumalai Colony Main, Madurai - 625 016.
            </div>
          </div>
                </Navbar.Brand>
           
                
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav className="me-5"> 
                        <Nav.Link href="/home" className="me-3 nav-link-hover"><b>Home</b></Nav.Link>
                        <Nav.Link href="/register" className="me-3 nav-link-hover"><b>Registration</b></Nav.Link>
                        <Nav.Link href="/appointment" className="me-3 nav-link-hover"><b>Appointment</b></Nav.Link>

                        <Dropdown className="nav-item dropdown me-3">
                            <Dropdown.Toggle id="pharmacy-dropdown" variant='light' className = "nav-link-hover" style={{ backgroundColor: 'white' }}>
                                <b>Pharmacy</b>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-menu position-absolute">
                            <Link to="/addmedicine" className="dropdown-item">Add Medicine</Link>
                                <Link to="/billing" className="dropdown-item">Billing</Link>
                                <Link to="/billinghistory" className="dropdown-item">Billing History</Link>
                                <Link to="/stock" className="dropdown-item">Stock</Link>
                                <Link to="/purchase" className="dropdown-item">Purchase</Link>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown className="nav-item dropdown me-3">
                            <Dropdown.Toggle id="pharmacy-dropdown" className = "nav-link-hover" variant='light' style={{ backgroundColor: 'white' }}>
                                <b>Doctor</b>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-menu position-absolute">
                            <Link to="/calendar" className="dropdown-item">Appointment History</Link>
                            <Link to="/Doctor" className="dropdown-item">Prescription</Link>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Nav.Link href="/" className="me-3 nav-link-hover">
                        <img
                      src="https://cdn-icons-png.flaticon.com/128/6568/6568599.png"
                      alt="Report Icon"
                      style={{ width: '30px', height: '30px',cursor:'pointer'}}
                    />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

           
        </div>
        </>
    );
};

export default NavigationBar;