import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Alert, Modal } from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import AppointmentsCalendar from './appointmentscalendar';
import TablePopup from './PopupTable';

function Appointment() {
    const [formData, setFormData] = useState({
        uhid:'',
        doctorName: 'Dr.P.Priyanka Angline B.D.S',
        patientName: '',
        mobileNo: '',
        age: '',
        gender: '',
        medicalAffiliation: '',
        selectedDate: '',
        selectedTime: '',
        uhidSearch: ''
    });
    const [uhidOptions, setUHIDOptions] = useState([]); // State to store fetched UHID options
    const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
    const [highlightedFields, setHighlightedFields] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false); // Add state for button disabling
    const [selectedUHID, setSelectedUHID] = useState('');
    const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);
    const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
    const [popupRows, setPopupRows] = useState([]);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showAlert, setShowAlert] = useState(false); 
  
    const [popupmsg, setPopupmsg] = useState('');

    useEffect(() => {
        fetchUHIDs(); // Fetch all UHIDs when the component mounts
    }, [])

    const fetchUHIDs = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/patientregistration/listuhids`);
            setUHIDOptions(response.data);

        } catch (error) {
            console.error('Error fetching UHIDs:', error);
        }
    };

    const handleRowSelect = (row) => {
        // Update the form data with the selected row
        setFormData({
          ...formData,
          uhid: row.uhid,
          patientName: row.firstName + " " + row.lastName,
          mobileNo: row.mobileNumber,
          age: row.age,
          gender: row.gender
        });
      
        setShowPopup(false); // Hide the popup
      };

    const handleUHIDSelection = (e) => {
        setSelectedUHID(e.target.value);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleAppointmentClick = () => {
        openModal();
    }

    // const handleUHIDSelect = async (e) => {
    //     try {
    //         const response = await axios.get(`${config.apiUrl}/registration/${selectedUHID}`);
    //         const patientData = response.data;
    //         setFormData({
    //             ...formData,
    //             patientName: patientData.firstName + " " + patientData.lastName,
    //             mobileNo: patientData.mobileNumber,
    //             age: patientData.age,
    //             gender: patientData.gender
    //         });
    //     } catch (error) {
    //         console.error('Error fetching patient data:', error);
    //         if (error.response && error.response.status === 404) {
    //             setShowNotFoundMessage(true);
    //             setTimeout(() => {
    //                 setShowNotFoundMessage(false);
    //             }, 3000);
    //         }
    //     }
    // };

    const handleSearch = async () => {
        const searchTerm = selectedUHID.trim(); 
      
        if (isNaN(searchTerm)) {
          try {
            const response = await axios.get(`${config.apiUrl}/registration/${searchTerm}`);
            const patientData = response.data;
      
            if (patientData) {
              setFormData({
                ...formData,
                uhid: patientData.uhid,
                patientName: patientData.firstName + " " + patientData.lastName,
                mobileNo: patientData.mobileNumber,
                age: patientData.age,
                gender: patientData.gender
              });
            } else {
                setShowAlert(true);
                setPopupmsg('Patient not found');
                setTimeout(() => {
                  setShowAlert(false);
              }, 2000);
            }
          } catch (error) {
            if (error.response && error.response.status === 404) {
              setShowAlert(true);
              setPopupmsg('Patient not found');
              setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            } else {
              console.error('Error:', error);
            }
          }
        } else {
          try {
            const response = await axios.get(`${config.apiUrl}/patientregistration/mobile/${searchTerm}`);
            const patientData = response.data;
            console.log('patientData',patientData);
      
            if (Array.isArray(patientData) && patientData.length > 1) {
              setPopupRows(patientData); // Set the rows for the popup
              setShowPopup(true); // Show the popup
            } else if (patientData) {
              setFormData({
                ...formData,
                uhid: patientData[0].uhid,
                patientName: patientData[0].firstName + " " + patientData[0].lastName,
                mobileNo: patientData[0].mobileNumber,
                age: patientData[0].age,
                gender: patientData[0].gender
                // Update other fields with patient data
              });
            } else {
                setShowAlert(true);
                setPopupmsg('Patient not found');
                setTimeout(() => {
                  setShowAlert(false);
              }, 2000);
            }
          } catch (error) {
            if (error.response && error.response.status === 404) {
              setShowAlert(true);
              setPopupmsg('Patient not found');
              setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            } else {
              console.error('Error:', error);
            }
          }
        }
      };
      

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setHighlightedFields((prevFields) => prevFields.filter((field) => field !== name));
        setShowNotFoundMessage(false); 
    };

    const handlePatientNameChange = (event) => {
        const value = event.target.value;
        const regex = /^[a-zA-Z.\s]*$/;
        if (regex.test(value) || value === '') {
            setFormData({ ...formData, patientName: value });
        }
    };

    const handleMobileNoChange = (event) => {
        const value = event.target.value;
        const regex = /^[0-9]{0,10}$/;
        if (regex.test(value) || value === '') {
            setFormData({ ...formData, mobileNo: value });
        }
    };

    const handleAgeChange = (event) => {
        const value = event.target.value;
        const age = parseInt(value, 10);
        if (!isNaN(age) && age >= 0 && age <= 100) {
            setFormData({ ...formData, age: value });
        } else {
            setFormData({ ...formData, age: '' });
        }
    };


    useEffect(() => {
        if (formData.selectedDate) {
            fetchAvailableTimeSlots();
        }
    }, [formData.selectedDate]);

    const fetchAvailableTimeSlots = async () => {
        try {
            const response = await axios.get(`${config.apiUrl}/availableTimeSlots`, {
                params: { selectedDate: formData.selectedDate }
            });
            setAvailableTimeSlots(response.data);
        } catch (error) {
            console.error('Error fetching available time slots:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const requiredFields = [
            "patientName",
            "mobileNo",
            "age",
            "gender",
            "medicalAffiliation",
            "selectedDate",
            "selectedTime"
        ];

        const emptyFields = requiredFields.filter(field => !formData[field]);
        if (emptyFields.length > 0) {
            setHighlightedFields(emptyFields);

            setTimeout(() => {
                setHighlightedFields([]);
            }, 3000);
            return;
        }

        const currentDate = new Date().toISOString().split('T')[0];
        if (formData.selectedDate < currentDate) {
            alert('Please select a future date.');
            return;
        }

        try {
            setSubmitting(true);
            const response = await axios.post(`${config.apiUrl}/appointment`, formData);
            setFormData({
                doctorName: 'Dr.P.Priyanka Angline B.D.S',
                uhid:'',
                patientName: '',
                mobileNo: '',
                age: '',
                gender: '',
                medicalAffiliation: '',
                selectedDate: '',
                selectedTime: '',
                uhidSearch:'',
            });

            setShowSuccessMessage(true);
            setPopupmsg('Appointment Booked Successfully');
            setTimeout(() => {
              setShowSuccessMessage(false);
          }, 2000);

        } catch (error) {
            console.error('Error submitting form:', error);
            setSubmitting(false);
        } finally {
            setSubmitting(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 8) {
            setFormData({
                doctorName: 'Dr.P.Priyanka Angline B.D.S',
                uhid:'',
                patientName: '',
                mobileNo: '',
                age: '',
                gender: '',
                medicalAffiliation: '',
                selectedDate: '',
                selectedTime: '',
                uhidSearch: ''
            });
        }
    };

    const handlecancel = ()=>{
        setFormData({
            doctorName: 'Dr.P.Priyanka Angline B.D.S',
            uhid:'',
            patientName: '',
            mobileNo: '',
            age: '',
            gender: '',
            medicalAffiliation: '',
            selectedDate: '',
            selectedTime: '',
            uhidSearch: ''
        });
    }

    const today = new Date().toISOString().split('T')[0];

    return (
        <>
            <style>
                {`
                .doctorname{
                  text-align:end;
                }
                .custom-alert {
                    max-width: 400px; 
                    width: 100%; 
                    z-index: 3; 
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                  }
     .highlighted {
        border: 1px solid red;
    }
    .custom-modal {
        max-width: 1000px; 
        min-height: 400px; 
    }
    .datatable {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
      }
      
      /* Modal content */
      .datatable-content {
        background-color: #fefefe;
        width:50%;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        position: relative; /* Make the content relative for absolute positioning */
      }
      
      /* Close button */
      .datatable-close {
        color: #aaa;
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: 28px;
        font-weight: bold;
        cursor: pointer;
      }
      
      .datatable-close:hover,
      .datatable-close:focus {
        color: black;
        text-decoration: none;
      }
      
      /* Animation for modal */
      .datatable-content {
        animation: slideIn 0.5s forwards;
      }
      
      @keyframes slideIn {
        from {
          transform: translateY(-100%);
          opacity: 0;
        }
        to {
          transform: translateY(0);
          opacity: 1;
        }
      }
      @media (max-width: 666px){
        .doctorname{
          text-align:start;
        }
        Form{
          font-size:12px; 
          
        }
        .datatable-content{
          width:100%;
        }
        @media (max-width: 345px){
          Form{
            font-size:10px; 
            
          }
        }
      }
      
    `}
            </style>
            <div className="container-fluid mt-3 position-relative">
            <Row className="mb-2 align-items-center">
    <Col lg={6} md={5} xs={10}>
        <h2><b>Appointment Form</b></h2>
    </Col>
    <Col lg={5} md={7} xs={12} className='mt-2 text-lg-end text-md-start'>
        <div className="input-group">
            <h5 className='mt-1' ><b >Search</b></h5>
            <input
                type="text"
                placeholder="Search by UHID or Mobile No"
                name="uhidSearch"
                value={formData.uhidSearch}
                onChange={handleChange}
                list="uhidOptions"
                className="form-control me-2 ms-2 rounded"
                onBlur={handleUHIDSelection}
                onKeyDown={handleKeyDown}
            />
            <datalist id="uhidOptions">
                {uhidOptions.map((option, index) => (
                    <option key={index} value={option} />
                ))}
            </datalist>
            <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={handleSearch}
            >
                <FontAwesomeIcon icon={faSearch} />
            </button>
        </div>
    </Col>
</Row>




                <div className="row">
          <div className="col-md-6 offset-md-3">

          <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
        <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </symbol>
        <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
        </symbol>
        <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </symbol>
      </svg>

            {showAlert && (
              <div className="position-absolute alert alert-danger  d-flex align-items-center custom-alert" role="alert">
                <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                  <use href="#exclamation-triangle-fill" />
                </svg>
                <div>
                {popupmsg}
                </div>
              </div>
            )}

            {showSuccessMessage && (
              <div className="position-absolute alert alert-success d-flex align-items-center custom-alert" role="alert">
                <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:">
                  <use href="#check-circle-fill" />
                </svg>
                <div>
                  {popupmsg}
                </div>
              </div>
            )}
           
  
          </div>
        </div>

                <div className="bg-white border ps-4 pe-4 pb-2 m-2 content-appoint">
                
                    <Form onSubmit={handleSubmit}>
                        <Row className="mt-3"><Col className="mb-2 doctorname"><p>DOCTOR NAME : <strong>Dr.P.Priyanka Angline B.D.S</strong></p></Col></Row>

                        <Row className="mb-2">
                            <Col>
                                <Form.Group controlId="patientName">
                                    <Form.Label><b>Patient Name</b></Form.Label>
                                    <Form.Control type="text" placeholder="Enter patient name" name="patientName" value={formData.patientName} onChange={handlePatientNameChange} className={highlightedFields.includes("patientName") ? "highlighted" : ""} readOnly />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId="mobileNo">
                                    <Form.Label><b>Mobile No</b></Form.Label>
                                    <Form.Control type="tel" pattern="[0-9]{10}" placeholder="Enter mobile number" name="mobileNo" value={formData.mobileNo} onChange={handleMobileNoChange} className={highlightedFields.includes("mobileNo") ? "highlighted" : ""} readOnly />
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row className="mb-2">
                            <Col>
                                <Form.Group controlId="age">
                                    <Form.Label><b>Age</b></Form.Label>
                                    <Form.Control type="number" min="0" max="100" placeholder="Enter age" name="age" value={formData.age} onChange={handleAgeChange} className={highlightedFields.includes("age") ? "highlighted" : ""} readOnly />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId="gender">
                                    <Form.Label><b>Gender</b></Form.Label>
                                    <Form.Control as="select" name="gender" value={formData.gender} onChange={handleChange} className={highlightedFields.includes("gender") ? "highlighted" : ""} readOnly>
                                        <option value="">Select gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                        </Row>

                        <Row className="mb-2">
                            <Col>
                                <Form.Group controlId="selectedDate">
                                    <Form.Label><b>Date</b></Form.Label>
                                    <Form.Control type="date" value={formData.selectedDate} onChange={handleChange} name="selectedDate" min={today} className={highlightedFields.includes("selectedDate") ? "highlighted" : ""} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="selectedTime">
                                    <Form.Label><b>Time</b></Form.Label>
                                    <Form.Control as="select" value={formData.selectedTime} onChange={handleChange} name="selectedTime" className={highlightedFields.includes("selectedTime") ? "highlighted" : ""}>
                                        <option value="">Select time slot</option>
                                        {availableTimeSlots.map((timeSlot, index) => (
                                            <option key={index} value={timeSlot}>{timeSlot}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="mb-2" controlId="medicalAffiliation">
                            <Form.Label><b>Medical Affiliation</b></Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter medical affiliation" name="medicalAffiliation" value={formData.medicalAffiliation} onChange={handleChange} className={highlightedFields.includes("medicalAffiliation") ? "highlighted" : ""} />
                        </Form.Group>


                        <Row >
                            <Col xs="auto" className="ms-auto">
                                <Button type="button" onClick={handlecancel} style={{ backgroundColor: "rgb(72, 194,205)", color: "white" }}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs="auto">
                                <Button type="submit" disabled={submitting} style={{ backgroundColor: "rgb(72, 194,205)", color: "white" }}>
                                    {submitting ? 'Submitting...' : 'Submit'}
                                </Button>
                            </Col>
                        </Row>
                    </Form>

        
                    <Modal show={isModalOpen} onHide={closeModal} dialogClassName="custom-modal">
                        <Modal.Header closeButton>
                            <Modal.Title>Appointment Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AppointmentsCalendar></AppointmentsCalendar>
                        </Modal.Body>
                        <Modal.Footer>

                        </Modal.Footer>
                    </Modal>
                    {showPopup && (
  <div className="modal datatable">
    <div className="modal-content datatable-content" style={{display: 'flex', flexDirection: 'column' }}>
      <span className="close datatable-close" onClick={() => setShowPopup(false)}>&times;</span>
      <div className="mt-4">
        <TablePopup rows={popupRows} onSelect={handleRowSelect} />
      </div>
    </div>
  </div>
)}

                </div>
            </div>
        </>

    );
}

export default Appointment;
